import React from "react"
import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import axios from 'axios'
import clas from 'classnames'
import { cdnApi, getWorksDetailStatic } from "@mk/services"
import { queryToObj } from "@mk/utils"
import { calcClipPath, getImageScale } from "../shared/utils"
import { MkPictureData } from '../shared/types'
import Effects from "./Effects"
import { isPhone } from "./Effects/tools"

import "./index.scss"
import { getImgEditAreaId, wrapImgCompId } from "../shared/dom"

/** 编辑信息 */
interface State {
  effectKey: number
  fillImgSrc: string
  imgUrl: string
  ready: boolean
  error: '' | '图片尺寸过大' | '网络错误'
}

const LoadImage = (url: string) => {
  const videoList = ['mov', 'mp4']
  const isVideo = videoList.some(item => url.includes(item))
  return new Promise<{ loadEvent, image: HTMLImageElement } | null>((resolve, reject) => {
    if (isVideo) {
      resolve(null)
      return
    }

    const image = new Image()
    image.onload = (loadEvent) => {
      resolve({
        loadEvent,
        image
      })
    }
    image.onerror = (e) => {
      reject(e)
    }
    // console.count(url)
    image.src = url
  })
}

const getImageSize = async (ossPath: string) => {
  const res = await axios
    .get(`${cdnApi(ossPath)}?x-oss-process=image/info`)
  const image = res.data as any
  const width = Number(image.ImageWidth.value)
  const height = Number(image.ImageHeight.value)
  return {
    width, height
  }
}

function startRender(rendered) {
  // Rendering start
  requestAnimationFrame(rendered)
}

function loaded(rendered) {
  return new Promise((resolve, reject) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(rendered)
      resolve('')
    })
  })
  // requestAnimationFrame(startRender)
}

class MkPicture extends React.Component<PlatformCompProps<MkPictureData>, State>{

  imgMaskRef = React.createRef<HTMLImageElement>()

  imgContainerRef = React.createRef<HTMLDivElement>()

  loading = false

  constructor(props) {
    super(props)
    // TODO:
    this.state = {
      error: '',
      ready: false,
      effectKey: Math.random(),
      fillImgSrc: '',
      imgUrl: '',
    }
  }

  async componentDidMount() {
    const { lifecycle: {
      didMount, didLoaded
    } } = this.props
    try {
      await this.initInEditor()
      await this.loadImg()
      didLoaded?.()
    } catch (err) {
      console.error('loadImg error', err)
      try {
        await this.loadImg(false)
        didLoaded?.()
      } catch (err2) {
        console.log('err2', err2)
        this.setState({
          error: '图片尺寸过大'
        })
        didLoaded?.()
      }
    }
    setTimeout(() => {
      if (!this.props.controledValues.ossPath) {
        didLoaded?.()
      }
    }, 500)
  }

  componentDidUpdate(pre) {
    if (!this.props.controledValues.ossPath && this.props.controledValues.binaryData) {
      return
    }
    if (this.props.controledValues.ossPath !== pre.controledValues.ossPath) {
      this.loadImg()
      if (this.props.editorSDK) {
        const { controledValues: { ossPath, materialId } } = this.props
        getImageScale(ossPath).then((scale) => {
          const imageOriginWidth = scale.width
          const imageOriginHeight = scale.height
          const resData = this.getFillImageData({
            orgWidth: imageOriginWidth,
            orgHeight: imageOriginHeight,
            ossPath,
            materialId,
          })
          this.props.editorSDK?.changeCompAttr(this.props.id, resData)
        })
      }
      // if (this.imgMaskRef.current) {
      //   this.imgMaskRef.current.style.visibility = 'visible'
      // }
    }
    if (this.props.controledValues.baseW > pre.controledValues.baseW) {
      this.loadImg()
    }
  }

  getFillImageData = (param: {
    orgWidth: number
    orgHeight: number
    ossPath: string
    materialId: string
  }) => {
    const { controledValues, containerInfo } =
      this.props
    const { orgWidth, orgHeight, ossPath, materialId } = param
    // const imageSize = calcImageSizeInCanvas(orgWidth, orgHeight, canvaInfo.canvaW, canvaInfo.canvaH)
    //  覆盖原有
    const filldata = { ...(controledValues || {}) }
    // filldata.pcEditorPicId = url
    filldata.ossPath = ossPath
    filldata.materialId = materialId
    if (orgWidth / orgHeight > containerInfo.width / containerInfo.height) {
      filldata.baseH = containerInfo.height
      filldata.baseW = (containerInfo.height * orgWidth) / orgHeight
    } else {
      filldata.baseW = containerInfo.width
      filldata.baseH = (containerInfo.width * orgHeight) / orgWidth
    }

    filldata.orgWidth = orgWidth
    filldata.orgHeight = orgHeight

    // filldata.hasCrop = true
    // console.log('filldata', filldata)
    // console.log('orgWidth', orgWidth)
    // console.log('orgHeight', orgHeight)
    // console.log('containerInfo.width', containerInfo)
    filldata.cropData = {
      left: 0,
      top: 0,
      width: containerInfo.width,
      height: containerInfo.height,
    }

    return filldata
  }

  get computedWrapStyle() {
    const { controledValues } = this.props
    const { baseW, baseH } = controledValues as any
    return {
      position: `absolute`,
      width: `${(baseW)} px`,
      height: `${(baseH)} px`,
      overflow: `hidden`,
      transform: `translate(0px, 0px) rotate(0deg)`
    }
  }

  getImgStyle(): React.CSSProperties {
    const { controledValues, containerInfo, viewerSDK } = this.props
    const { imgUrl } = this.state
    const { cropData, baseW, baseH, flipVertical, flipHorizontal, maskImage } = controledValues
    if (!cropData) return {}

    const resizeImageTransformLeft = flipHorizontal ? baseW - cropData.width - cropData.left : cropData.left
    const resizeImageTransformTop = flipVertical ? baseH - cropData.height - cropData.top : cropData.top
    const style: React.CSSProperties = {
      width: `100%`,
      height: `100%`,
      borderRadius: this.getBorderRadius(),
      backgroundImage: `url("${imgUrl}")`,
      imageRendering: '-webkit-optimize-contrast',
      transform: `scale(${flipHorizontal ? -1 : 1}, ${flipVertical ? -1 : 1}) translateZ(0)`,
      backgroundPosition: `-${resizeImageTransformLeft}px -${resizeImageTransformTop}px`,
      maskImage,
      WebkitMaskImage: maskImage,
    }
    if (!this.props.viewerSDK) {
      style.pointerEvents = 'none'
    }
    if (baseW && baseH) {
      style.backgroundSize = `${baseW}px ${baseH}px`
    }
    return style
  }

  initInEditor = async () => {
    const { controledValues, lifecycle, canvaInfo, containerInfo, id, viewerSDK, pageInfo } = this.props
    if (viewerSDK) return
    const { didLoaded, didMount } = lifecycle

    const { ossPath, cropData } = controledValues as any

    const areaInfo = {
      width: pageInfo?.width || canvaInfo.canvaW,
      height: pageInfo?.height || canvaInfo.canvaH
    }

    // 判断数据
    /**
     * 如果有裁剪数据，直接返回
     */
    if (cropData) {
      didMount?.({
        boxInfo: {
          width: cropData.width,
          height: cropData.height
        },
        // data: newData
      })
    } else if (ossPath) {
      /** 如果没有宽高数据 */
      const img = await LoadImage(cdnApi(ossPath))
      let imageOriginWidth
      let imageOriginHeight
      if (img) {
        // console.log('img', img.image.width, img.image.height)
        imageOriginWidth = img.image.width
        imageOriginHeight = img.image.height
      } else {
        const imgInfo = await getImageSize(ossPath)
        imageOriginWidth = imgInfo.width
        imageOriginHeight = imgInfo.height
      }

      let rwidth = containerInfo?.width
      let rheight = containerInfo?.height
      if (imageOriginWidth > 0 && imageOriginHeight > 0) {
        if (parseFloat(imageOriginWidth) >= parseFloat(imageOriginHeight)) {
          rwidth = areaInfo.width * 0.5
          rheight = rwidth * imageOriginHeight / imageOriginWidth
        } else {
          rheight = areaInfo.height * 0.5
          rwidth = rheight * imageOriginWidth / imageOriginHeight
          if (rwidth > areaInfo.width) {
            rwidth = areaInfo.width * 0.5
            rheight = rwidth * imageOriginHeight / imageOriginWidth
          }
        }
        const _baseW = rwidth || imageOriginWidth
        const _baseH = rheight || imageOriginHeight
        // 补全数据
        const nextData: MkPictureData = {
          version: 1,
          baseW: _baseW,
          baseH: _baseH,
          orgWidth: Number(imageOriginWidth),
          orgHeight: Number(imageOriginHeight),
          ossPath,
          borderRadius: 0,
          // width: _baseW,
          // height: _baseH,
          cropData: {
            left: 0,
            top: 0,
            width: rwidth,
            height: rheight,
          }
        }
        console.log('commit pic data', nextData)

        didMount?.({
          boxInfo: {
            height: rheight,
            width: rwidth
          },
          data: nextData
        })

      } else {
        console.error('获取图片宽高失败')
        didMount?.({
          boxInfo: {
            height: canvaInfo.canvaW * 0.25,
            width: canvaInfo.canvaH * 0.25,
          }
        })
        // didLoaded?.()
      }
    }
  }

  loadImg = async (resize = true) => {
    const { lifecycle: { didLoaded }, editorSDK, controledValues, canvaInfo, id, worksType } = this.props
    const { binaryData, ossPath, isDragUpload } = controledValues

    /** 处理从相册上传的逻辑 */
    if (binaryData && !ossPath) {
      // if (this.imgContainerRef.current) {
      //   this.imgContainerRef.current.style.outline = 'none'
      // }
      return this.setState({ imgUrl: binaryData, ready: true })
    }
    const isPosterMode = worksType === 'poster'
    const imageScaleRate = isPosterMode ? 2 : 4
    let canvasScale = +queryToObj().canvas_scale
    if (Number.isNaN(canvasScale)) {
      canvasScale = 1
    }

    if (this.loading) return

    if (editorSDK) {
      const resizeWidth = isPosterMode ? undefined : Math.min(canvaInfo.canvaW, controledValues.baseW) * canvasScale * imageScaleRate
      const url = cdnApi(ossPath, { resizeWidth })
      this.loading = true
      try {
        await LoadImage(url)
        this.setState({ imgUrl: url, ready: true })
        this.loading = false
      } catch (err) {
        this.loading = false
        throw new Error(err as any)
      }
    } else {
      // 在 viewer
      this.loading = true
      // return new Promise(resolve => {
      // console.log('imageScale', imageScale, controledValues)
      /** h5 放大3倍 */
      const imageScale = isPosterMode ? await getImageSize(ossPath) : {
        width: controledValues.orgWidth,
        height: controledValues.orgHeight
      }
      const resizeWidth = isPosterMode ? imageScale.width : Math.min(Math.min(canvaInfo.canvaW, controledValues.baseW) * canvasScale * imageScaleRate, imageScale.width)
      // const resizeWidth = Math.min(Math.min(canvaInfo.canvaW, controledValues.baseW) * canvasScale * imageScaleRate, imageScale.width)
      let _resize = resize
      if (resizeWidth >= imageScale.width) {
        _resize = false
      }
      /**
       * （画布宽度，base宽度）* 画布缩放，原图宽度，三者取最小值
      */
      // const viewerImageUrl = cdnApi(ossPath)
      const viewerImageUrl = cdnApi(ossPath, _resize ? {
        resizeWidth,
      } : undefined)

      try {
        await LoadImage(viewerImageUrl)

        // if (this.imgContainerRef.current) {
        //   this.imgContainerRef.current.style.outline = 'none'
        // }
        this.setState({ imgUrl: viewerImageUrl, ready: true })
        // resolve(true)

        const rendered = () => {
          // Render complete
          didLoaded?.()
        }
        await loaded(rendered)
        this.loading = false
      } catch (err) {
        console.log('loadImg origin err', err)
        this.loading = false
        throw new Error(err as any)
      }
    }
  }

  onFillImgLoad = (url: string) => {
    this.setState({ fillImgSrc: url })
  }


  getBorderRadius = () => {
    const { controledValues, containerInfo } = this.props
    const { ossPath, borderRadius, baseW } = controledValues as any
    const result = `${borderRadius * ((containerInfo?.width > containerInfo?.height) ? containerInfo.height : containerInfo.width) / 200}px`
    return result
  }


  getShadowStyle() {
    const { controledValues } = this.props

    if (!controledValues.shadow?.enable) return 'none'
    const { blur, direction, color } = controledValues.shadow
    const shadowLength = 6 // 需要处理缩放参数
    const tx = Math.cos(Math.PI / 180 * direction - 45) * shadowLength
    const ty = Math.cos(Math.PI / 180 * direction + 45) * shadowLength
    return `drop-shadow(${color} ${tx}px ${ty}px ${blur}px)`
  }

  getMinSize = (value: number) => {
    const status = isPhone()
    if (status) {
      return Math.max(5, value)
    } else {
      return value
    }
  }

  renderEffectShadow = (baseIndex: number) => {
    const { controledValues: { effects }, id } = this.props
    const shadows = effects?.shadow || []
    const strokes = effects?.stroke || []
    const result: string[] = []
    const preOffsetXRight = 0
    const preOffsetYBottom = 0
    const preOffsetXLeft = 0
    const preOffsetYTop = 0
    if (this.getShadowStyle() !== 'none') {
      result.push(this.getShadowStyle())
    }

    for (let index = strokes.length - 1;index >= 0;index--) {
      const { color, boxSize } = strokes[index]
      const strokeLen = strokes[index].value
      const value = this.getMinSize(+strokeLen)
      if (value != null && `${value}` !== '0') {
        const strokeShadows = [
          `drop-shadow(${+value - preOffsetXRight}px 0px 0px ${color})`,
          `drop-shadow(${-1 * (+value) - preOffsetXLeft}px 0px 0px ${color})`,
          `drop-shadow(0px ${+value - preOffsetYBottom}px 0px ${color})`,
          `drop-shadow(0px ${-1 * (+value) - preOffsetYTop}px 0px ${color})`,
        ]
        result.push(...strokeShadows)
      }
    }
    for (let index = 0;index < shadows.length;index++) {
      const shadow = shadows[index]
      const offsetX = this.getMinSize(+shadow.offsetX)
      const offsetY = this.getMinSize(+shadow.offsetY)
      const dropShadow = `drop-shadow(${offsetX}px ${offsetY}px ${shadow.blur}px ${shadow.color})`
      result.push(dropShadow)
    }
    return result
  }

  getFilter = () => {
    const { controledValues: { effects }, id } = this.props
    const result: React.ReactNode[] = []
    const baseIndex = 99
    result.push(...this.renderEffectShadow(baseIndex))
    return result.join(' ')
  }

  get maskStyle() {
    const { controledValues: { mask }, containerInfo } = this.props
    const maskWidth = Math.min(containerInfo.width, containerInfo.height)
    return {
      width: containerInfo?.width,
      height: containerInfo?.height,
      borderRadius: this.getBorderRadius(),
      clipPath: calcClipPath(mask, maskWidth)
    }
  }

  render() {
    const { controledValues, id, containerInfo, viewerSDK, worksType } = this.props
    const isPosterMode = worksType === 'poster'
    const { error, ready, imgUrl } = this.state
    const { materialId, ossPath, orgWidth } = controledValues

    if (error) {
      return (
        <div className="mk_img_error_tip" style={{
          fontSize: 30,
          textAlign: 'center',
          margin: '30px auto'
        }}>{error}</div>
      )
    }

    return (
      <div style={{
        filter: this.getFilter(),
      }}>
        <div
          className={clas("mk_comp_picture", ready && 'ready')}
          id={`${wrapImgCompId(id)}`}
          ref={this.imgContainerRef}
          data-materialid={materialId}
          data-picturemask={`${!!materialId}`}
          style={this.maskStyle}>
          {
            ready && (
              <>
                <div
                  className="crop-canvas picture_display_container"
                  id={`__img_container__${id}`}
                  style={this.getImgStyle()}
                >
                  {!isPosterMode && viewerSDK && <img src={imgUrl}
                    loading="lazy"
                    alt="error" className="mk-picture-qcord" />}
                </div>

                {controledValues.effects && (
                  <Effects
                    effects={controledValues.effects} id={id} width={containerInfo?.width}
                    height={containerInfo?.height} effectKey={this.state.effectKey}
                    onFillImgLoad={this.onFillImgLoad} />
                )}
              </>
            )
          }
        </div>
        {this.state.fillImgSrc && <img src={this.state.fillImgSrc} alt="error" className="effect_fill_img" style={{ borderRadius: this.getBorderRadius() }}></img>}
        <div id={getImgEditAreaId(id)}></div>
      </div>
    )
  }
}

export default MkPicture

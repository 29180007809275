import { cdnApi } from "@mk/services"
import { EventEmitterClass } from "@mk/utils"
import axios from "axios"

export const getNearBaseW = (width: number, maxWidth: number) => {
  return 2 * width < maxWidth ? 2 * Math.floor(width / 300) * 300 + 300 : maxWidth
}

export const getPictureDict = (key: string): string => {
  return key
}

// 计算蒙版路径
export const calcClipPath = (mask, width: number) => {
  if (!mask?.content) {
    return ""
  }
  const path = mask.content
  const pathWidth = mask.width || 38
  if (path.indexOf("clip-path") > -1) {
    const clipPath = path.replace("clip-path: ", "")
    return clipPath.replace(/;/g, "")
  } else {
    const WIDTH = width / pathWidth
    const PRECISION = 1
    if (WIDTH && path && PRECISION) {
      const output = path.replace(/([-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?)/g, (match) => {
        return (+match * WIDTH).toFixed(PRECISION)
      })
      return `path("${output}")`
    }
  }
  return ""
}

export const getImageScale = (
  ossPath: string,
  options?: {
    resizeWidth?: number | undefined
    focusResize?: boolean | undefined
  }
) => {
  return new Promise<{
    width: number
    height: number
  }>((resolve, reject) => {
    const img = new Image()
    img.src = cdnApi(ossPath, options)
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = () => {
      console.log("图片加载失败")
      reject()
    }
  })
}
export const setStyle = (dom: HTMLElement | null, value: any) => {
  if (!dom) return
  const { style } = dom
  for (const key of Object.keys(value)) {
      style[key] = value[key]
  }
}

export class ResourceDownloadManager extends EventEmitterClass {
  private downloadMap: Record<string, {
    status: 'downloading' | 'done'
    data
  }> = {}

  getDownloadSvg = async (svgUrl: string, callback) => {
    if (this.downloadMap[svgUrl]) {
      if (this.downloadMap[svgUrl].status === 'downloading') {
        this.on(svgUrl, callback)
      } else {
        callback(this.downloadMap[svgUrl].data)
      }
    } else {
      this.downloadMap[svgUrl] = {
        status: 'downloading',
        data: null
      }
      this.on(svgUrl, callback)
      const svgDom = await axios.get(svgUrl)
      this.downloadMap[svgUrl] = {
        status: 'done',
        data: svgDom.data
      }
      this.emit(svgUrl, svgDom.data)
    }
  }
}
